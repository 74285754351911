import appConfig from '@/utils/appConfig'

export const riskOptions = [
  {
    label: 'All',
    value: "all"
  },
  {
    label: 'Identified',
    value: true
  },
  {
    label: 'Unidentified',
    value: false
  },
]

export const pagesOptions = [5, 10,20, 50, 100]

export const addressNumberOptions = [
  {
    label: 'Any',
    value: "any"
  },
  {
    label: 'More than',
    value: 'more'
  },
  {
    label: 'Less',
    value: 'less'
  },
]

export const percentOfTxs = [
  {
    label: 'More than',
    value: '$gt'
  },
  {
    label: 'Less than',
    value: '$lt'
  },
  {
    label: 'More or equal',
    value: '$gte'
  },
  {
    label: 'Less or equal',
    value: '$lte'
  },
]

export const alertLogicOperators = [
  {
    label: 'AND',
    value: '$allOf'
  },
  {
    label: 'OR',
    value: '$anyOf'
  },
]

const baseAlertsDirectionList = [
  {
    label: 'Incoming txs',
    value: 0
  },
  {
    label: 'Outgoing txs',
    value: 1
  },
  {
    label: 'Both',
    value: 2
  },
]

export const alertDirection = appConfig.VUE_APP_EXCLUDE_MULTI_DIRECTION
  ? baseAlertsDirectionList.filter(item => item.value === 0)
  : baseAlertsDirectionList;

export const alertPriority = [
  {
    label: 'High',
    value: 100
  },
  {
    label: 'Medium',
    value: 50
  },
  {
    label: 'Low',
    value: 0
  },
]

export const alertChanel = [
  {
    label: 'Dashboard only',
    value: 'dashboard'
  },
  {
    label: 'Email',
    value: 'email'
  }
]

export const quantityPropsList = [
  {
    label: 'BTC',
    key: 'amount',
  },
  // {
  //   label: 'Share',
  //   key: 'share',
  // },
]

const baseAlertsTriggersList = [
  {
    label: 'Received (Native token)',
    outLabel: 'Sent (Native token)',
    bothLabel: 'Sent/Received (Native token)',
    value: 'address'
  },
  {
    label: 'Received (Fiat)',
    outLabel: 'Sent (Fiat)',
    bothLabel: 'Sent/Received (Fiat)',
    value: 'amountCur'
  },
  {
    label: 'Received from “Owner”',
    outLabel: 'Sent to “Owner”',
    bothLabel: 'Sent/Received to "Owner"',
    value: 'owner'
  },
  {
    label: 'Received from “Score”',
    outLabel: 'Sent to “Score”',
    bothLabel: 'Sent/Received to “Score”',
    value: 'funds.score'
  },
  {
    label: 'Received from “Type / Tag”',
    outLabel: 'Sent to “Type / Tag”',
    bothLabel: 'Sent/Received to “Type / Tag”',
    value: 'funds.id'
  },
  {
    label: 'Received from “Unhosted wallet”',
    outLabel: 'Sent to “Unhosted wallet”',
    bothLabel: 'Sent to “Unhosted wallet”',
    value: 'funds.unhosted'
  }
];

export const alertsTriggersList = appConfig.VUE_APP_EXCLUDE_AMOUNT_CUR
  ? baseAlertsTriggersList.filter(item => item.value !== 'amountCur')
  : baseAlertsTriggersList;
